<template>
    <div>
        <!--搜索-->
        <el-row>
            <el-col :span="8">
                <el-input placeholder="请输入标题" v-model="queryInfo.title" :clearable="true" @clear="search"
                          @keyup.native.enter="search" size="small" style="min-width: 500px">
                    <el-select v-model="queryInfo.categoryId" slot="prepend" placeholder="请选择分类" :clearable="true"
                               @change="search" style="width: 160px">
                        <el-option :label="item.name" :value="item.id" v-for="item in categoryList"
                                   :key="item.id"></el-option>
                    </el-select>
                    <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
                </el-input>
            </el-col>
        </el-row>

        <el-table :data="blogList">
            <el-table-column label="序号" type="index" width="50" align="center" ></el-table-column>
            <el-table-column label="标题" prop="title" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="分类" prop="category.name" width="150" align="center"></el-table-column>
            <el-table-column label="创建时间" width="200" align="center">
                <template v-slot="scope">{{ scope.row.create_time }}</template>
            </el-table-column>
            <el-table-column label="最近更新" width="200" align="center">
                <template v-slot="scope">{{ scope.row.update_time }}</template>
            </el-table-column>
            <el-table-column label="操作" width="200" align="center">
                <template v-slot="scope">
                    <el-button type="primary" icon="el-icon-edit" size="mini" @click="goBlogEditPage(scope.row.id)">编辑
                    </el-button>
                    <el-popconfirm title="确定删除吗？" icon="el-icon-delete" iconColor="red"
                                   @confirm="deleteArticleById(scope.row.id)">
                        <el-button size="mini" type="danger" icon="el-icon-delete" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <!--分页-->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="queryInfo.pageNum"
                       :page-sizes="[10, 20, 30, 50]" :page-size="queryInfo.pageSize" :total="total"
                       layout="total, sizes, prev, pager, next, jumper" background>
        </el-pagination>
    </div>
</template>

<script>
    import {getDataByQuery, deleteArticleById} from '@/api/blog'

    export default {
        name: "BlogList",
        data() {
            return {
                queryInfo: {
                    title: '',
                    categoryId: null,
                    pageNum: 1,
                    pageSize: 10
                },
                blogList: [],
                categoryList: [],
                total: 0,
                dialogVisible: false,
                blogId: 0,
                radio: 1,
                visForm: {
                    appreciation: false,
                    recommend: false,
                    commentEnabled: false,
                    top: false,
                    published: false,
                    password: '',
                }
            }
        },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                getDataByQuery(this.queryInfo).then(res => {
                    this.blogList = res.data.list
                    this.categoryList = res.data.categories
                    this.total = res.data.total
                })
            },
            search() {
                this.queryInfo.pageNum = 1
                this.queryInfo.pageSize = 10
                this.getData()
            },

            //监听 pageSize 改变事件
            handleSizeChange(newSize) {
                this.queryInfo.pageSize = newSize
                this.getData()
            },
            //监听页码改变的事件
            handleCurrentChange(newPage) {
                this.queryInfo.pageNum = newPage
                this.getData()
            },
            goBlogEditPage(id) {
                this.$router.push(`/blog/edit/${id}`)
            },
            deleteArticleById(id) {
                    deleteArticleById(id).then(res => {
                        this.msgSuccess(res.message)
                        this.getData()
                    })
            }
        }
    }
</script>

<style scoped>
    .el-button + span {
        margin-left: 10px;
    }
</style>