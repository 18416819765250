import axios from '@/util/request'

export function getDataByQuery(queryInfo) {
	return axios({
		url: 'article/get',
		method: 'GET',
		params: {
			...queryInfo
		}
	})
}

export function deleteArticleById(id) {
	return axios({
		url: 'article/delete',
		method: 'DELETE',
		params: {
			id
		}
	})
}

export function getCategoryAndTag() {
	return axios({
		url: 'get/categoryAndTag',
		method: 'GET'
	})
}

export function saveArticle(article) {
	return axios({
		url: 'article/save',
		method: 'POST',
		data: {
			...article
		}
	})
}




export function getArticleById(id) {
	return axios({
		url: 'article/blog',
		method: 'GET',
		params: {
			id
		}
	})
}

export function updateArticle(article) {
	return axios({
		url: 'article/blog',
		method: 'POST',
		data: {
			...article
		}
	})
}